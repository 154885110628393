import { fetchAuthSession } from 'aws-amplify/auth'
import { format } from 'date-fns'
import * as Sentry from '@sentry/react'
import axios from 'axios'

const dateTransformer = (data) => {
  if (data instanceof Date) {
    return format(data, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  }

  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val))
  }

  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]))
  }

  return data
}

const requestInterceptors = [
  [
    async (config) => {
      try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {}
        const jwtToken = accessToken?.toString()

        config.headers['Authorization'] = jwtToken
      } catch (err) {
        Sentry.captureException(err)
      }

      return config
    },
    (error) => Promise.reject(error),
  ],
  [],
]

const responseInterceptors = [
  [
    (response) => response,
    ({ response }) => {
      if (response) {
        const error = new Error(response.data?.message)
        error.code = response.data?.__type
        error.name = response.data?.__type
        error.statusCode = response.status

        return Promise.reject(error)
      }
    },
  ],
]

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transformRequest: [dateTransformer, ...axios.defaults.transformRequest],
})

requestInterceptors.forEach((interceptor) => {
  instance.interceptors.request.use(...interceptor)
})

responseInterceptors.forEach((interceptor) => {
  instance.interceptors.response.use(...interceptor)
})

export default instance
