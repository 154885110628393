import axios from 'axios'
import { createContext, useContext, useState } from 'react'
import { fetchAuthSession } from 'aws-amplify/auth'
import { format } from 'date-fns'
import { useWorkspace } from './use-workspace'

const dateTransformer = (data) => {
  if (data instanceof Date) {
    return format(data, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  }

  if (Array.isArray(data)) {
    return data.map(dateTransformer)
  }

  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]))
  }

  return data
}

const AxiosContext = createContext(null)

export function useAxios() {
  const ctx = useContext(AxiosContext)

  if (!ctx) {
    throw new Error('useAxios must be used within an AxiosProvider')
  }

  return ctx
}

export function AxiosProvider({ children }) {
  const { workspace } = useWorkspace()
  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    transformRequest: [dateTransformer, ...axios.defaults.transformRequest],
  })

  instance.interceptors.request.use(
    async function (config) {
      try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {}

        config.headers['Authorization'] = accessToken.toString()
        setIsSessionExpired(false)
      } catch (err) {
        setIsSessionExpired(true)
      }

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.request.use(
    function (config) {
      try {
        config.headers['Workspace'] = workspace.id
      } catch (err) {}

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return <AxiosContext.Provider value={{ axios: instance, isSessionExpired }}>{children}</AxiosContext.Provider>
}
