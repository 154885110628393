import { useSessionStorage } from '@uidotdev/usehooks'
import { useContext, createContext } from 'react'
import { useACL } from './use-acl'

const WorkspaceContext = createContext(null)

export function useWorkspace() {
  return useContext(WorkspaceContext)
}

export function WorkspaceProvider({ children }) {
  const { workspaces } = useACL()
  const [workspace, setWorkspace] = useSessionStorage('Eduseg::Workspace', workspaces?.[0])

  return (
    <WorkspaceContext.Provider value={{ workspace, workspaces, setWorkspace }}>{children}</WorkspaceContext.Provider>
  )
}
