import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export default function ErrorBoundary() {
  const error = useRouteError()

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
      <div className="px-10 md:px-40 py-20 bg-white md:rounded-lg shadow-xl max-w-screen-md">
        <div className="flex flex-col items-center">{renderComponent(error.status)}</div>
      </div>
    </div>
  )
}

function renderComponent(status) {
  switch (status) {
    case 401:
      return <Unauthorized />
    case 404:
      return <NotFound />
    default:
      return <UnknownError />
  }
}

function Unauthorized() {
  return (
    <>
      <Heading>401</Heading>
      <Lead>Sem permissão</Lead>
      <Paragraph>Você não tem permissão para acessar esta página.</Paragraph>
    </>
  )
}

function NotFound() {
  return (
    <>
      <Heading>404</Heading>
      <Lead>Página não encontrada</Lead>
      <Paragraph>A página que você está procurando não existe.</Paragraph>
    </>
  )
}

function UnknownError() {
  return (
    <>
      <Heading>:(</Heading>
      <div className="space-y-2.5">
        <Lead>Ocorreu um erro inesperado</Lead>
        <Paragraph>Por favor, tente atualizar a página, se persistir, entre em contato conosco.</Paragraph>
      </div>
    </>
  )
}

function Heading({ children }) {
  return <h1 className="font-semibold text-9xl text-blue-600 mb-4">{children}</h1>
}

function Lead({ children }) {
  return (
    <h6 size="2xl" className="font-semibold text-2xl md:text-3xl text-gray-80 mb-2">
      {children}
    </h6>
  )
}

function Paragraph({ children }) {
  return <p className="text-gray-500 md:text-lg">{children}</p>
}
