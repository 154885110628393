import { lazy } from 'react'
import { createRoutesFromElements, useLocation, Route, Outlet, Navigate } from 'react-router-dom'
import ErrorBoundary from 'error'
import AuthProvider, { fetchAuthData, useAuth } from 'hooks/use-auth'
import { ACLProvider } from 'hooks/use-acl'
import { WorkspaceProvider } from 'hooks/use-workspace'
import { AxiosProvider } from 'hooks/use-axios'

const Layout = lazy(() => import('layouts/app'))

function PrivateRoutes() {
  const { authStatus } = useAuth()
  const location = useLocation()

  if (authStatus === 'unauthenticated') {
    const redirect = `${location.pathname}${location.search}`
    const search = new URLSearchParams({ redirect }).toString()

    return <Navigate to={{ pathname: '/auth/login', search }} replace />
  }

  return (
    <ACLProvider>
      <WorkspaceProvider>
        <AxiosProvider>
          <Outlet />
        </AxiosProvider>
      </WorkspaceProvider>
    </ACLProvider>
  )
}

export default createRoutesFromElements(
  <Route loader={fetchAuthData} element={<AuthProvider />} errorElement={<ErrorBoundary />}>
    <Route element={<PrivateRoutes />}>
      <Route element={<Layout />}>
        <Route index lazy={() => import('routes/overview')} />
        <Route path="support" lazy={() => import('routes/support')} />
        <Route path="settings/*" lazy={() => import('routes/settings')} />
        <Route path="orders" lazy={() => import('routes/orders')} />
        <Route path="orders/:id" lazy={() => import('routes/orders/$id')} />
        <Route path="orders/:id/*" lazy={() => import('routes/orders/$id')} />
        <Route path="orders/new" lazy={() => import('routes/orders/new')} />
        <Route path="users" lazy={() => import('routes/users')} />
        <Route path="users/:id/*" lazy={() => import('routes/users/$id')} />
        <Route path="users/add" lazy={() => import('routes/users/add')} />
        <Route path="users/admins" lazy={() => import('routes/users/admins')} />
        <Route path="users/tags" lazy={() => import('routes/users/tags')} />
        <Route path="users/jobs" lazy={() => import('routes/users/jobs')} />
        <Route path="enrollments" lazy={() => import('routes/enrollments')} />
        <Route path="enrollments/add" lazy={() => import('routes/enrollments/add')} />
        <Route path="enrollments/vacancies" lazy={() => import('routes/enrollments/vacancies')} />
        <Route path="enrollments/scheduled" lazy={() => import('routes/enrollments/scheduled')} />
        <Route path="enrollments/:id" lazy={() => import('routes/enrollments/$id')} />
        <Route path="courses" lazy={() => import('routes/courses')} />
        <Route path="courses/add" lazy={() => import('routes/courses/add')} />
        <Route path="webhooks" lazy={() => import('routes/webhooks')} />
      </Route>
      {/* Redirect out of the app */}
      <Route path="konviva" lazy={() => import('routes/konviva')} />
    </Route>

    <Route path="auth/*" lazy={() => import('routes/auth')} />
  </Route>
)
