import axios from 'axios'
import { useEffect, useState } from 'react'

export function useIp() {
  const [ip, setIp] = useState(null)

  useEffect(() => {
    const doRequest = async () => {
      if (ip) {
        return null
      }

      try {
        const { data } = await axios.get('https://api.ipify.org/?format=json')
        setIp(data?.ip)
      } catch {
        setIp(null)
      }
    }

    doRequest()
  }, [ip])

  return ip
}
