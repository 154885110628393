import { useContext, createContext, useCallback } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useAuth } from './use-auth'
import axios from 'libs/axios'

export const ACLContext = createContext(null)

export function useACL() {
  const ctx = useContext(ACLContext)

  if (!ctx) {
    throw new Error('useACL must be used within an ACLContext')
  }

  return ctx
}

export function ACLProvider({ children }) {
  const { user } = useAuth()
  const {
    data: { acls, workspaces },
  } = useSuspenseQuery({
    queryKey: ['settings', user.id],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get('/account/', { signal })
      return data
    },
  })

  const canAccess = useCallback(
    ({ workspace, roles = ['USER'] }) => {
      return acls.some(({ sk, roles: aclRoles }) => {
        if (sk === '*') {
          // Grant access if ACL applies to all workspaces, regardless of roles
          return true
        }

        // Check if the ACL matches the workspace and has at least one allowed role
        return sk === workspace && roles.some((role) => aclRoles.includes(role))
      })
    },
    [acls]
  )

  return (
    <ACLContext.Provider
      value={{
        canAccess,
        acls,
        workspaces: canAccess('*', ['ADMIN']) ? [{ ...user, id: '*' }, ...workspaces] : workspaces,
      }}
    >
      {children}
    </ACLContext.Provider>
  )
}

export function CanAccess({ workspace, roles, children, fallback = <></> }) {
  const { canAccess } = useACL()

  // Check if the workspace is assigned a valid role in the ACLs
  if (canAccess({ workspace, roles })) {
    return children
  }

  return fallback
}
