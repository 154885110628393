import { useMemo, useCallback, useContext, useState, createContext, useEffect } from 'react'
import { useIp } from 'hooks/use-ip'
import { Outlet, useLoaderData } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import * as Auth from 'aws-amplify/auth'

const AuthContext = createContext(null)

export function useAuth() {
  const ctx = useContext(AuthContext)

  if (!ctx) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return ctx
}

async function fetchUserAttributes() {
  const userAttrs = await Auth.fetchUserAttributes()
  return { id: userAttrs['custom:user_id'], ...userAttrs }
}

export async function fetchAuthData() {
  try {
    const user = await fetchUserAttributes()

    return {
      user,
      authStatus: 'authenticated',
    }
  } catch (err) {
    return { authStatus: 'unauthenticated' }
  }
}

export default function AuthProvider() {
  const ip = useIp()
  const authData = useLoaderData()
  const [authStatus, setAuthStatus] = useState(authData.authStatus)
  const [user, setUser] = useState(authData?.user)

  useEffect(() => {
    Sentry.setUser({
      id: user?.id,
      email: user?.email,
      ip_address: ip,
    })
  }, [user, ip])

  const signIn = useCallback(
    async ({ username, password }) => {
      const signInOut = await Auth.signIn({
        username,
        password,
        options: {
          clientMetadata: { ip },
        },
      })

      if (signInOut?.isSignedIn) {
        setAuthStatus('authenticated')
        setUser(await fetchUserAttributes())
      }

      return signInOut
    },
    [ip]
  )

  const signUp = useCallback(
    async ({ id, name, username, password }) => {
      return await Auth.signUp({
        username,
        password,
        options: {
          clientMetadata: { ip },
          userAttributes: {
            name,
            email: username,
            'custom:user_id': id,
          },
        },
      })
    },
    [ip]
  )

  const confirmSignUp = useCallback(
    async ({ username, code: confirmationCode }) => {
      return await Auth.confirmSignUp({
        username,
        confirmationCode,
        options: {
          clientMetadata: { ip },
        },
      })
    },
    [ip]
  )

  const autoSignIn = useCallback(async () => {
    return await Auth.autoSignIn()
  }, [])

  const updatePassword = useCallback(
    async ({ password }) => {
      const signInOut = await Auth.confirmSignIn({
        challengeResponse: password,
        options: {
          clientMetadata: { ip },
        },
      })

      if (signInOut?.isSignedIn) {
        setAuthStatus('authenticated')
        setUser(await fetchUserAttributes())
      }

      return signInOut
    },
    [ip]
  )

  const resendSignUpCode = useCallback(
    async ({ username }) => {
      return await Auth.resendSignUpCode({
        username,
        options: { clientMetadata: { ip } },
      })
    },
    [ip]
  )

  const signInWithCode = useCallback(
    async ({ username }) => {
      return await Auth.signIn({
        username,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP',
          clientMetadata: { ip },
        },
      })
    },
    [ip]
  )

  const confirmSignInWithCode = useCallback(
    async ({ code }) => {
      const confirmSignInOutput = await Auth.confirmSignIn({
        challengeResponse: code,
        options: {
          clientMetadata: { ip },
        },
      })

      if (confirmSignInOutput?.isSignedIn) {
        setAuthStatus('authenticated')
        setUser(await fetchUserAttributes())
      }

      return confirmSignInOutput
    },
    [ip]
  )

  const signOut = useCallback(async () => {
    try {
      return await Auth.signOut()
    } catch {
    } finally {
      setAuthStatus('unauthenticated')
    }
  }, [])

  const resetPassword = useCallback(
    async ({ username }) => {
      return await Auth.resetPassword({
        username,
        options: {
          clientMetadata: { ip },
        },
      })
    },
    [ip]
  )

  const confirmResetPassword = useCallback(
    async ({ username, password: newPassword, code: confirmationCode }) => {
      return await Auth.confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
        options: {
          clientMetadata: { ip },
        },
      })
    },
    [ip]
  )

  const ctx = useMemo(
    () => ({
      user,
      authStatus,
      signIn,
      signInWithCode,
      confirmSignInWithCode,
      autoSignIn,
      signUp,
      confirmSignUp,
      resendSignUpCode,
      updatePassword,
      signOut,
      resetPassword,
      confirmResetPassword,
    }),
    [
      user,
      authStatus,
      signIn,
      signInWithCode,
      confirmSignInWithCode,
      autoSignIn,
      signUp,
      confirmSignUp,
      resendSignUpCode,
      updatePassword,
      signOut,
      resetPassword,
      confirmResetPassword,
    ]
  )

  return (
    <AuthContext.Provider value={ctx}>
      <Outlet />
    </AuthContext.Provider>
  )
}
