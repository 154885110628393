import React from 'react'
import { Amplify } from 'aws-amplify'
import { Smallest as Logo } from 'components/logo'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient } from '@tanstack/react-query'
import amplifyconfig from 'amplifyconfiguration.json'
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import routes from './routes'
import * as Sentry from '@sentry/react'

Amplify.configure(amplifyconfig)

Sentry.init({
  dsn: 'https://0671a4e2434d58392a5f0a53d1195fb6@o1259136.ingest.us.sentry.io/4506837230682112',
  environment: process.env.NODE_ENV,
  release: `${process.env.REACT_APP_BUILD_TIME}.${process.env.REACT_APP_BUILD_HASH}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter(routes)

function Pulse() {
  return <Logo className="w-14 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse" />
}

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} fallbackElement={<Pulse />} />
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </QueryClientProvider>
    </HelmetProvider>
  )
}
